import React, {Component} from 'react';
import { Helmet } from "react-helmet";
import { Button, InputItem, Toast } from 'antd-mobile';

import './AuthQuery.css';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { message } from 'antd';


class AuthPassword extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          openId: props.history.location.state.openId,
          userGuid: props.history.location.state.userGuid,
          mobileNo: props.history.location.state.mobileNo,
          userName: props.history.location.state.userName,
        };
    }


    componentDidUpdate() {
        const {
            wxCheckPasswordActionStatusT,
        } = this.props;

        if(wxCheckPasswordActionStatusT === ACTION_STATUS.ERROR) {
            this.props.initWxCheckPasswordActionStatus();
            Toast.fail("密码错误", 2);
        }
        if(wxCheckPasswordActionStatusT === ACTION_STATUS.SUCCESS) {
            this.props.initWxCheckPasswordActionStatus();
            const userGuid = this.state.userGuid;
            const openId = this.state.openId;
            const userName = this.state.userName;
            this.props.history.replace({
                pathname: '/home/fund', 
                state: {
                    openId,
                    userGuid,
                    userName,
                }
             });
        }

    }


    passwordInputOnChanged = (value) => {
        this.setState({
            ...this.state,
            password: value,
        });
    }


    confirmBtnOnClick = () => {
        ////////////////////
        const isErroruserResult = this.props.isErroruserResult;
        if(isErroruserResult) {
            Toast.fail("年末与托管行数据核对中，暂停数据查询，会尽快开放，给您带来的不便，敬请谅解！", 2);
            return;
        }


        const {
            openId,
            password,
        } = this.state;
        this.props.wxCheckPassword(openId, password);
    }


    goToChangePassword = () => {
        const userGuid = this.state.userGuid;
        const openId = this.state.openId;
        const userName = this.state.userName;
        const mobileNo = this.state.mobileNo;
        this.props.history.push({
            pathname: '/home/change-password', 
            state: {
                openId,
                userGuid,
                userName,
                mobileNo,
            }
        });
    }


    render() {
        const {
            password,
          } = this.state;
        const confirmBtnDisabled = !!!password;

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>查询验证</title>
                </Helmet>
                <div className="authQueryBox">
                    <InputItem 
                        value={ password }
                        onChange={this.passwordInputOnChanged}
                        type='password'
                        maxLength={20}
                        className="authCodeInputItem" 
                        placeholder="请输入密码" />
                    <Button 
                        style={{ backgroundColor: '#1970b6', color: 'white' }}
                        className="confirmBtn"
                        disabled={confirmBtnDisabled}
                        onClick={this.confirmBtnOnClick}>确定</Button>   
                    <div>重要提示: 本微信服务号于2024年8月1日起停止美元基金查询，请通过海外托管行发送的邮件进行查询</div>
                    <div>温馨提示: 国内基金数据已经通过托管行复核，基金净值为管理费后，表现费前。</div>
                    <div><a href='#' onClick={()=>this.goToChangePassword()}>密码修改</a></div>
                </div>
            </div>
        );
    }

}

export default AuthPassword;
