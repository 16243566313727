import {connect} from 'react-redux';
import RiskEvaluationDashboard from '../components/RiskEvaluationDashboard';

import {
  // Load Risk Evaluations
  loadRiskEvaluations,
  initLoadRiskEvaluationsActionStatus,
  changeParams,
  evaluationWXUser,
  initEvaluationWXUserActionStatus,
} from '../investorRegisterActions';

const mapStateToProps = state => {
  return {
    // Load Risk Evaluations
    loadRiskEvaluationsActionStatus: state.investorRegister.loadRiskEvaluationsActionStatus,
    riskEvaluations: state.investorRegister.riskEvaluations,
    personalRiskEvaluations: state.investorRegister.personalRiskEvaluations,
    orgRiskEvaluations: state.investorRegister.orgRiskEvaluations,
    
    evaluationWXUserActionStatus: state.investorRegister.evaluationWXUserActionStatus,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
     // Load Risk Evaluations
    loadRiskEvaluations: () => dispatch(loadRiskEvaluations()),
    initLoadRiskEvaluationsActionStatus: () => dispatch(initLoadRiskEvaluationsActionStatus()),
    changeParams: params => dispatch(changeParams(params)),
    evaluationWXUser: (wxUserInfo) => dispatch(evaluationWXUser(wxUserInfo)),
    initEvaluationWXUserActionStatus: () => dispatch(initEvaluationWXUserActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskEvaluationDashboard);