// Send Captcha
export const START_SEND_CAPTCHAT = 'START_SEND_CAPTCHAT';
export const SEND_CAPTCHA_SUCCESST = 'SEND_CAPTCHA_SUCCESST';
export const SEND_CAPTCHA_FAILURET = 'SEND_CAPTCHA_FAILURET';
export const INIT_SEND_CAPTCHA_ACTION_STATUST = 'INIT_SEND_CAPTCHA_ACTION_STATUST';

// Auth Captcha
export const START_AUTH_CAPTCHAT = 'START_AUTH_CAPTCHAT';
export const AUTH_CAPTCHA_SUCCESST = 'AUTH_CAPTCHA_SUCCESST';
export const AUTH_CAPTCHA_FAILURET = 'AUTH_CAPTCHA_FAILURET';
export const INIT_AUTH_CAPTCHA_ACTION_STATUST = 'INIT_AUTH_CAPTCHA_ACTION_STATUST';

// auth password
export const START_WX_CHECK_PASSWORDT = 'START_WX_CHECK_PASSWORDT';
export const WX_CHECK_PASSWORD_SUCCESST = 'WX_CHECK_PASSWORD_SUCCESST';
export const WX_CHECK_PASSWORD_FAILURET = 'WX_CHECK_PASSWORD_FAILURET';
export const INIT_WX_CHECK_PASSWORD_ACTION_STATUST = 'INIT_WX_CHECK_PASSWORD_ACTION_STATUST';





///////////
export const START_IS_ERROR_USERT = 'START_IS_ERROR_USERT';
export const IS_ERROR_USER_SUCCESST = 'IS_ERROR_USER_SUCCESST';
export const IS_ERROR_USER_FAILURET = 'IS_ERROR_USER_FAILURET';
export const INIT_IS_ERROR_USER_ACTION_STATUST = 'INIT_IS_ERROR_USER_ACTION_STATUST';


