import React, {Component} from 'react';
import { Helmet } from "react-helmet";
import { SegmentedControl,Button, Popover  } from 'antd-mobile';
import PersonalRiskEvaluation from './PersonalRiskEvaluation';
import OrgRiskEvaluation from './OrgRiskEvaluation';
import './RiskEvaluation.css';

class RiskEvaluationDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      popoverVisible: true,
      openId: props.history.location.state.openId,
      // openId: props.history?.location?.state?.openId || '',
      isExpired: props.history.location.state.isExpired,
      // isExpired: props.history?.location?.state?.openId || 'NO',
    };
  }
  
  componentDidMount() {
    this.props.loadRiskEvaluations();
  }

  segmentedControlOnValueChanged = (value) => {
    if (value === '个人版') {
      this.setState({
        tabIndex: 0
      });
    }

    if (value === '机构版') {
      this.setState({
        tabIndex: 1
      });
    }
  }
  popoverOnclick = () => {
    this.setState({
      ...this.state,
      popoverVisible: false,
    });
  }
  render() {
    const {
      tabIndex,
      popoverVisible,
    } = this.state;

    return (

      <div className="riskEvaluationDashboard">
        <Helmet>
          <meta charSet="utf-8" />
          <title>基金投资者风险测评问卷</title>
        </Helmet>
        <div className="riskEvaluationSegmentedControl">
          <SegmentedControl 
            selectedIndex={tabIndex} 
            onValueChange={this.segmentedControlOnValueChanged}
            tintColor={'#1970b6'}
            values={['个人版', '机构版']} />
        </div>

        {
          tabIndex === 0 && 
          <div>
          <Popover mask
            visible={popoverVisible}
            overlay={[
              (
                <span>
                    <h2 align="center">合格投资者确认书</h2>
                    <div><br/></div>
                    <div><br/>请仔细并完整阅读以下重要提示，根据您的具体情况进行选择：</div>
                    <div><br/></div>
                    <div><br/>请您确认您或您所代表的机构是符合《中华人民共和国证券投资基金法》《私募投资基金监督管理条例》《私募投资基金监督管理暂行办法》《私募投资基金募集行为管理办法》《证券期货投资者适当性管理办法》及其他相关法律法规所认定的合格投资者</div>
                    <div><br/></div>
                    <div><br/>根据我国《私募投资基金募集行为管理办法》的规定，私募基金合格投资者的标准如下：</div>
                    <div><br/></div>
                    <div><br/>1.	具备相应风险识别能力和风险承担能力，投资于单只私募基金的金额不低于100万元且符合下列相关标准的单位和个人：</div>
                    <div><br/></div>
                    <div><br/>(1)	净资产不低于1000万元的机构；</div>
                    <div><br/>(2)	金融资产不低于300万元或者最近三年个人年均收入不低于50万元的个人。</div>
                    <div>前款所称金融资产包括银行存款、股票、债券、基金份额、资产管理计划、银行理财产品、信托计划、保险产品、期货权益等。</div>
                    <div><br/></div>
                    <div><br/>2.	下列投资者视为合格投资者：</div>
                    <div><br/></div>
                    <div><br/>(1)	社会保障基金、企业年金等养老基金，慈善基金等社会公益基金；</div>
                    <div><br/>(2)	依法设立并在中国基金业协会备案的私募基金产品；</div>
                    <div><br/>(3)	受国务院金融监督管理机构监管的金融产品；</div>
                    <div><br/>(4)	投资于所管理私募基金的私募基金管理人及其从业人员；</div>
                    <div><br/>(5)	法律法规、中国证监会和中国基金业协会规定的其他投资者。</div>
                    <div><br/>上海保银私募基金管理有限公司（以下简称“上海保银私募”）微信服务号所包含的信息和材料仅供一般性参考，不构成任何关于金融、财务、法律、税务或其他方面的意见或建议。</div>
                    <div><br/>市场有风险，投资需谨慎，过往业绩不预示未来表现。您在做出投资决策前应认真阅读相关产品合同，并自行承担投资风险。</div>
                    <div><br/>上海保银私募微信服务号由上海保银私募拥有和运营。上海保银私募微信服务号所载信息或材料均属于上海保银私募所有或关联公司所有，或者已经取得有权第三方必要授权。</div>
                    <div><br/>上海保银私募有权随时修改或删除上海保银私募微信服务号所载信息或材料。未经上海保银私募事先书面同意，任何人不得复制、摘编、引用、改写上海保银私募微信服务号所载信息或材料，亦不得以转载、转发、分发、存储于检索系统等形式将上海保银私募微信服务号所载信息或材料的全部或者部分用作任何商业或公共用途。</div>
                    <div><br/></div>
                  
                    
                    <div><br/>A.	我确认我或我所代表的机构符合“合格投资者”标准，而且同意接受上述所有内容</div>
                    <div><br/></div>
                    <div><br/>B.	我或我所代表的机构不符合“合格投资者”标准，或者不同意接受上述内容</div>
                    <div><br/></div>
                    <div align="center"><br/>
                      <Button size="small" 
                          type="primary"
                          inline
                          size="small"
                          onClick={this.popoverOnclick}
                      >A:同&nbsp;&nbsp;意</Button>
                      <Button size="small" 
                          type="primary"
                          inline
                          size="small"
                          
                      >B:不同意</Button>
                    </div>
                </span>
              ),
            ]}
            align={{
              overflow: { adjustY: -1, adjustX: -1 },
              offset: [0, 0],
            }}
          >
            <div></div>
        </Popover>
          
          <PersonalRiskEvaluation {...this.props} />
          </div>  
        }
        {/* 机构版 */}
        {
          tabIndex === 1 && 
          <OrgRiskEvaluation {...this.props} />
        }
      </div>
    );
  }
}

export default RiskEvaluationDashboard;