
const APPOINTMENT_TYPE_CODE = {
  PURCHASE: '0',
  REDEMPTION: '1',
};

const APPOINTMENT_TYPE_LABEL = {
  PURCHASE: '申购',
  REDEMPTION: '赎回',
};



const APPOINTMENT_FUND_CODE = {
  CVF: '4028f89454184f1b015418be3d300bb3',
  DCL: '56e788e84734448d9ac7e27465662152',
  ZJNF: '4028f8945611e32401592953386278c3',
  SLHL: '4028f8945571ef580155802d37210120',
};

const APPOINTMENT_FUND_LABEL = {
  CVF: '保银多空稳健1号私募证券投资基金',
  DCL: '保银多空稳健2号私募证券投资基金',
  ZJNF: '保银进取1号私募证券投资基金',
  SLHL: '保银石榴红了基金',
};
export const APPOINTMENT_FUND_MAP = [
  // 申购
  {
    code: APPOINTMENT_FUND_CODE.CVF,
    label: APPOINTMENT_FUND_LABEL.CVF
  },
  // 赎回
  {
    code: APPOINTMENT_FUND_CODE.DCL,
    label: APPOINTMENT_FUND_LABEL.DCL
  },
  {
    code: APPOINTMENT_FUND_CODE.ZJNF,
    label: APPOINTMENT_FUND_LABEL.ZJNF
  },
  {
    code: APPOINTMENT_FUND_CODE.SLHL,
    label: APPOINTMENT_FUND_LABEL.SLHL
  },
];

export const APPOINTMENT_TYPE_MAP = [
  // 申购
  {
    code: APPOINTMENT_TYPE_CODE.PURCHASE,
    label: APPOINTMENT_TYPE_LABEL.PURCHASE
  },
  // 赎回
  {
    code: APPOINTMENT_TYPE_CODE.REDEMPTION,
    label: APPOINTMENT_TYPE_LABEL.REDEMPTION
  },
];