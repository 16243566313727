import {connect} from 'react-redux';
import AuthTabs from '../components/AuthTabs';

import {
  // Send Captcha
  sendCaptcha,
  initSendCaptchaActionStatus,

  // Auth Captcha
  authCaptcha,
  initAuthCaptchaActionStatus,

  // Auth Password
  wxCheckPassword,
  initWxCheckPasswordActionStatus,


  // isErrUser
  isErrUser,
  initIsErrUserActionStatus,

} from '../authQueryActions';

import {
  // Load WX Auth Info
  loadWXAuthInfo,
  initLoadWXAuthInfoActionStatus,

  // Load UserInfo By OpenId
  loadUserInfo,
  initLoadUserInfoActionStatus,
} from '../../home/homeActions';

const mapStateToProps = state => {
  return {
    // Send Captcha
    sendCaptchaActionStatusT: state.authQueryT.sendCaptchaActionStatusT,
    authTokenT: state.authQueryT.authTokenT,

    // Auth Captcha
    authCaptchaActionStatusT: state.authQueryT.authCaptchaActionStatusT,

    // Load UserInfo By OpenId
    loadUserInfoActionStatus: state.home.loadUserInfoActionStatus,
    loadUserInfoResult: state.home.loadUserInfoResult,

    loadWXAuthInfoActionStatus: state.home.loadWXAuthInfoActionStatus,
    wxAuthInfo: state.home.wxAuthInfo,

    // Auth Password
    wxCheckPasswordActionStatusT: state.authQueryT.wxCheckPasswordActionStatusT,


    ////////////
    isErrorUserStatusT: state.authQueryT.isErrorUserStatusT,
    isErroruserResultT: state.authQueryT.isErroruserResultT,

  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Send Captcha
    sendCaptcha: (openId) => dispatch(sendCaptcha(openId)),
    initSendCaptchaActionStatus: () => dispatch(initSendCaptchaActionStatus()),

    // Auth Captcha
    authCaptcha: (openId, phoneNo, authToken, captcha) => dispatch(authCaptcha(openId, phoneNo, authToken, captcha)),
    initAuthCaptchaActionStatus: () => dispatch(initAuthCaptchaActionStatus()),

    // Load WX Auth Info
    loadWXAuthInfo: (code) => dispatch(loadWXAuthInfo(code)),
    initLoadWXAuthInfoActionStatus: () => dispatch(initLoadWXAuthInfoActionStatus()),

    // Load UserInfo By OpenId
    loadUserInfo: (openId) => dispatch(loadUserInfo(openId)),
    initLoadUserInfoActionStatus: () => dispatch(initLoadUserInfoActionStatus()),

    // Auth Password
    wxCheckPassword: (openId, password) => dispatch(wxCheckPassword(openId, password)),
    initWxCheckPasswordActionStatus: () => dispatch(initWxCheckPasswordActionStatus()),



    // isErrUser
    isErrUser: (openId) => dispatch(isErrUser(openId)),
    initIsErrUserActionStatus: () => dispatch(initIsErrUserActionStatus()),

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthTabs);