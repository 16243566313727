import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';

import {
    // change password
    START_WX_CHANGE_PASSWORDT,
    WX_CHANGE_PASSWORD_SUCCESST,
    WX_CHANGE_PASSWORD_FAILURET,
    INIT_WX_CHANGE_PASSWORD_ACTION_STATUST,

} from './changePasswordConstants';


const initialState = {
}


// change password
function startWxChangePassword(state) {
    return {
        ...state,
        wxChangePasswordActionStatusT: ACTION_STATUS.LOGINING,
    };
}
function wxChangePasswordSuccess(state, resp) {
    return {
        ...state,
        wxChangePasswordActionStatusT: ACTION_STATUS.SUCCESS,
    };
}
function wxChangePasswordFailure(state, err) {
    return {
        ...state,
        wxChangePasswordActionStatusT: ACTION_STATUS.ERROR,
        wxChangePasswordActionRespT: err,
    };
}
function initWxChangePasswordActionStatus(state) {
    return {
        ...state,
        wxChangePasswordActionStatusT: ACTION_STATUS.READY,
    };
}


export default createReducer(initialState, {
    // change password
    [START_WX_CHANGE_PASSWORDT]: startWxChangePassword,
    [WX_CHANGE_PASSWORD_SUCCESST]: wxChangePasswordSuccess,
    [WX_CHANGE_PASSWORD_FAILURET]: wxChangePasswordFailure,
    [INIT_WX_CHANGE_PASSWORD_ACTION_STATUST]: initWxChangePasswordActionStatus,

  
});
