import {
 // WX User Register
  START_REGISTER_WX_USER,
  REGISTER_WX_USER_SUCCESS,
  REGISTER_WX_USER_FAILURE,
  INIT_REGISTER_WX_USER_ACTION_STATUS,

  // Send WX User Register Captcha
  START_SEND_REGISTER_CAPTCHA,
  SEND_REGISTER_CAPTCHA_SUCCESS,
  SEND_REGISTER_CAPTCHA_FAILURE,
  INIT_SEND_REGISTER_CAPTCHA_ACTION_STATUS,

  // Load Risk Evaluations
  START_LOAD_RISK_EVALUATIONS,
  LOAD_RISK_EVALUATIONS_SUCCESS,
  LOAD_RISK_EVALUATIONS_FAILURE,
  INIT_LOAD_RISK_EVALUATIONS_ACTION_STATUS,
  CHANGE_PARAMS,

   // WX User Register
   START_EVALUATION_WX_USER,
   EVALUATION_WX_USER_SUCCESS,
   EVALUATION_WX_USER_FAILURE,
   INIT_EVALUATION_WX_USER_ACTION_STATUS,
} from './investorRegisterConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


// WX User Register
function startRegisterWXUser() {
  return {
    type: START_REGISTER_WX_USER
  };
}

export function registerWXUser(wxUserInfo) {
  return function(dispatch) {
    dispatch(startRegisterWXUser());
    httpClient
      .post(UrlConfig.REGISTER_WX_USER, wxUserInfo)
      .then(resp => dispatch(registerWXUserSuccess(resp)))
      .catch(err => dispatch(registerWXUserFailure(err)));
  };
}

function registerWXUserSuccess(resp) {
  return {
    type: REGISTER_WX_USER_SUCCESS,
    payload: resp
  };
}

function registerWXUserFailure(err) {
  return {
    type: REGISTER_WX_USER_FAILURE,
    payload: err
  };
}

export function initRegisterWXUserActionStatus() {
  return {
    type: INIT_REGISTER_WX_USER_ACTION_STATUS
  };
}

// Send WX User Register Captcha
function startSendRegisterCaptcha() {
  return {
    type: START_SEND_REGISTER_CAPTCHA
  };
}

export function sendRegisterCaptcha(openId, phoneNo) {
  const requestParams = {
    openId,
    phoneNo
  };

  return function(dispatch) {
    dispatch(startSendRegisterCaptcha());
    httpClient
      .post(UrlConfig.SEND_WX_USER_REGISTER_CAPTCHA, requestParams)
      .then(resp => dispatch(sendRegisterCaptchaSuccess(resp)))
      .catch(err => dispatch(sendRegisterCaptchaFailure(err)));
  };
}

function sendRegisterCaptchaSuccess(resp) {
  return {
    type: SEND_REGISTER_CAPTCHA_SUCCESS,
    payload: resp
  };
}

function sendRegisterCaptchaFailure(err) {
  return {
    type: SEND_REGISTER_CAPTCHA_FAILURE,
    payload: err
  };
}

export function initSendRegisterCaptchaActionStatus() {
  return {
    type: INIT_SEND_REGISTER_CAPTCHA_ACTION_STATUS
  };
}

// Load Risk Evaluations
function startLoadRiskEvaluations() {
  return {
    type: START_LOAD_RISK_EVALUATIONS
  };
}

export function loadRiskEvaluations() {
  return function(dispatch) {
    dispatch(startLoadRiskEvaluations());
    httpClient
      .get(UrlConfig.LOAD_RISK_EVALUATIONS)
      .then(resp => dispatch(loadRiskEvaluationsSuccess(resp)))
      .catch(err => dispatch(loadRiskEvaluationsFailure(err)));
  };
}

function loadRiskEvaluationsSuccess(resp) {
  return {
    type: LOAD_RISK_EVALUATIONS_SUCCESS,
    payload: resp
  };
}

function loadRiskEvaluationsFailure(err) {
  return {
    type: LOAD_RISK_EVALUATIONS_FAILURE,
    payload: err
  };
}

export function initLoadRiskEvaluationsActionStatus() {
  return {
    type: INIT_LOAD_RISK_EVALUATIONS_ACTION_STATUS
  };
}

export function changeParams(params) {
  return {
    type: CHANGE_PARAMS,
    payload: params
  };
}



// WX User Register
function startEvaluationWXUser() {
  return {
    type: START_EVALUATION_WX_USER
  };
}

export function evaluationWXUser(wxUserInfo) {
  return function(dispatch) {
    dispatch(startEvaluationWXUser());
    httpClient
      .post(UrlConfig.EVALUATION_RECON, wxUserInfo)
      .then(resp => dispatch(evaluationWXUserSuccess(resp)))
      .catch(err => dispatch(evaluationWXUserFailure(err)));
  };
}

function evaluationWXUserSuccess(resp) {
  return {
    type: EVALUATION_WX_USER_SUCCESS,
    payload: resp
  };
}

function evaluationWXUserFailure(err) {
  return {
    type: EVALUATION_WX_USER_FAILURE,
    payload: err
  };
}
export function initEvaluationWXUserActionStatus() {
  return {
    type: INIT_EVALUATION_WX_USER_ACTION_STATUS
  };
}
