
let APP_ID = '';
let FRONT_DN = '';

if(process.env.REACT_APP_ENV === 'prod') {
  APP_ID = 'wx2d5ffb69db09275e';
  FRONT_DN = 'wx.pinpointfund.cn';
} else if(process.env.REACT_APP_ENV === 'qa') {
  APP_ID = 'wx8e053cc4e910bf7a';
  FRONT_DN = 'wxqa.pinpointfund.cn';
} else {  // dev
  APP_ID = 'wx8e053cc4e910bf7a';
  FRONT_DN = 'wxqa.pinpointfund.cn';
}


const ENCODE_FUND_REDIRECT_URI = 'http%3a%2f%2f'+FRONT_DN+'%2fhome%2ffund-blank';
const ENCODE_AUTH_QUERY_REDIRECT_URI = 'http%3a%2f%2f'+FRONT_DN+'%2fhome%2fauth-query-blank';
const ENCODE_AUTH_QUERY_REDIRECT_URIT = 'http%3a%2f%2f'+FRONT_DN+'%2fhome%2fauth-query-blankT';
const ENCODE_REGISTER_REDIRECT_URI = 'http%3a%2f%2f'+FRONT_DN+'%2fhome%2finvestor-register-blank';
const ENCODE_APPOINTMENT_REDIRECT_URI = 'http%3a%2f%2f'+FRONT_DN+'%2fhome%2fappointment-blank';
const SERVER_DOWN_NOTICE_URL = 'https://'+FRONT_DN+'/home/server-down-notice';


const WX_AUTH_BASE_URL = 'https://open.weixin.qq.com/connect/oauth2/authorize';

const COMMON_PARAMS = 'response_type=code&scope=snsapi_base#wechat_redirect';

const FUND_AUTH_URL = `${WX_AUTH_BASE_URL}?appid=${APP_ID}&redirect_uri=${ENCODE_FUND_REDIRECT_URI}&${COMMON_PARAMS}`;

const AUTH_QUERY_AUTH_URL = `${WX_AUTH_BASE_URL}?appid=${APP_ID}&redirect_uri=${ENCODE_AUTH_QUERY_REDIRECT_URI}&${COMMON_PARAMS}`;

const AUTH_QUERY_AUTH_URLT = `${WX_AUTH_BASE_URL}?appid=${APP_ID}&redirect_uri=${ENCODE_AUTH_QUERY_REDIRECT_URIT}&${COMMON_PARAMS}`;

const INVESTOR_REGISTER_AUTH_URL = `${WX_AUTH_BASE_URL}?appid=${APP_ID}&redirect_uri=${ENCODE_REGISTER_REDIRECT_URI}&${COMMON_PARAMS}`;

const APPOINTMENT_AUTH_URL = `${WX_AUTH_BASE_URL}?appid=${APP_ID}&redirect_uri=${ENCODE_APPOINTMENT_REDIRECT_URI}&${COMMON_PARAMS}`;

const WXAuthUtil = {
  APP_ID,
  FUND_AUTH_URL,
  AUTH_QUERY_AUTH_URL,
  AUTH_QUERY_AUTH_URLT,
  INVESTOR_REGISTER_AUTH_URL,
  APPOINTMENT_AUTH_URL,
  SERVER_DOWN_NOTICE_URL,
};

export default WXAuthUtil;