import React, {Component} from 'react';
import { Tabs, Badge } from 'antd-mobile';

import './AuthQuery.css';
import AuthQuery from './AuthQuery';
import AuthPassword from './AuthPassword';


class AuthTabs extends Component {
    tabs = [
        { title: <Badge>验证 码验证</Badge> },
        { title: <Badge>密码 验证</Badge> },
    ];

    constructor(props) {
        super(props);
        let authTabIdx = props.history.location.state.authTabIdx;
        authTabIdx = isNaN(authTabIdx) || (authTabIdx!==0 && authTabIdx!==1) ? 0 : authTabIdx;
        this.authTabIdx = authTabIdx;
    }

    componentDidMount() {
        const openId = this.props.loadUserInfoResult.resultData.openId;
        this.props.isErrUser(openId);

    }


    render() {
        return (
            <div>
                <Tabs tabs={this.tabs} initialPage={this.authTabIdx} >
                    <div><AuthQuery {...this.props} /></div>
                    <div><AuthPassword {...this.props} /></div>
                </Tabs>
            </div>
        );
    }

}

export default AuthTabs;
