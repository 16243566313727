import React, {Component} from 'react';
import { Button } from 'antd-mobile';

const INTERVAL_TIMES = 200;

class CountDownButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      btnTitle: '获取验证码',
      timer: null,
      times: INTERVAL_TIMES,
      btnDisabled: false,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  // 开始倒计时
  startCountDown = () => {
    const timer = setInterval(() => {
      this.countDown();
    }, 1000);

    this.setState({
      timer
    });
  }

  countDown() {
    let times = this.state.times;
    let btnTitle = `${times}s后重新获取`;
    
    times--;

    if (times <= 0) {
      this.endCountDown();
      return;
    }

    this.setState({
      btnTitle,
      btnDisabled: true,
      times
    });
    
  }

  endCountDown() {
    clearInterval(this.state.timer);

    this.setState({
      btnTitle: '获取验证码',
      timer: null,
      times: INTERVAL_TIMES,
      btnDisabled: false,
    });
  }

  componentWillUnmount() {
    this.endCountDown();
  }

  render() {
    const {
      btnTitle,
      btnDisabled
    } = this.state;

    const {
      buttonOnClick
    } = this.props;

    return (
      <div>
        <style>
          {`
            .button {
              width: 140px;
              font-size: 14px !important;
              color: white !important;
              height: 40px !important;
              line-height: 40px !important;
              margin-top: 0px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          `}
        </style>
        <Button 
          className="button"
          style={{ backgroundColor: !btnDisabled ? '#ff8a10' : 'gray' }}
          disabled={btnDisabled} 
          onClick={buttonOnClick}
          >{ btnTitle }</Button>
      </div>
    );
  }
}

export default CountDownButton;