import React, {Component} from 'react';
import { Button, InputItem, Toast } from 'antd-mobile';
import Select from 'antd/es/select';
import { Helmet } from "react-helmet";
import CountDownButton from '../../../components/CountDownButton';
import './InvestorRegister.css';
import { CERTIFICATE_TYPE_MAP } from '../../../../utils/CertificateTypeUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

const Option = Select.Option;

// 证件类型
const CertificateTypeOptions = [];
for (let i = 0; i < CERTIFICATE_TYPE_MAP.length; i++) {
  CertificateTypeOptions.push(<Option key={CERTIFICATE_TYPE_MAP[i].code}>{CERTIFICATE_TYPE_MAP[i].label}</Option>);
}

class InvestorRegister extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openId: props.history.location.state.openId,
      score: props.history.location.state.score,
      rating: props.history.location.state.rating,
      name: '',
      mobileNo: '',
      captcha: '',
      certificateType: "0",
      certificateNo: "",
    };
  }

  componentDidUpdate() {
    const {
      // WX User Register
      registerWXUserActionStatus,

      // Send WX User Register Captcha
      sendRegisterCaptchaActionStatus
    } = this.props;

    if (registerWXUserActionStatus === ACTION_STATUS.SUCCESS) {
      Toast.success("注册成功", 1);
      this.props.initRegisterWXUserActionStatus();

      this.setState({
        name: '',
        mobileNo: '',
        captcha: '',
        certificateType: '0',
        certificateNo: '',
      });

      // 跳转到温馨提示页面
      this.props.history.replace({
        pathname: '/home/investor-register-blank', 
        state: {
        }
      });
    }

    if (registerWXUserActionStatus === ACTION_STATUS.ERROR) {
      Toast.fail("注册失败", 1);
      this.props.initRegisterWXUserActionStatus();
    }

    // Send WX User Register Captcha
    if (sendRegisterCaptchaActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initSendRegisterCaptchaActionStatus();
      
      // 短信发送成功后，开始倒计时
      this.countDownButton.startCountDown();
    }

    if (sendRegisterCaptchaActionStatus === ACTION_STATUS.ERROR) {
      this.props.initSendRegisterCaptchaActionStatus();
    }
  }

  registerBtnOnClicked = () => {
    const {
      openId,
      score,
      rating,
      name,
      mobileNo,
      captcha,
      certificateType,
      certificateNo,
    } = this.state;
    
    if (!!!name) {
      Toast.fail("请输入您的姓名", 2);
      return;
    }

    if (!!!mobileNo) {
      Toast.fail("请输入您的手机号", 2);
      return;
    }

    if (!!!captcha) {
      Toast.fail("请输入您的短信验证码", 2);
      return;
    }

    if (!!!certificateNo) {
      Toast.fail("请输入您的证件号", 2);
      return;
    }

    const authToken = this.props.sendRegisterCaptchaAuthToken;
    this.props.registerWXUser({
      openId,
      score,
      rating,
      name,
      mobileNo,
      captcha,
      certificateType,
      certificateNo,
      authToken,
    });
  }

  nameInputOnChanged = (value) => {
    this.setState({
      name: value,
    });
  }

  mobileNoInputOnChanged = (value) => {
    this.setState({
      mobileNo: value,
    });
  }

  captchaInputOnChanged = (value) => {
    this.setState({
      captcha: value,
    });
  }

  certTypeSelectOnChange = (value) => {
    this.setState({
      certificateType: value,
    });
  }

  certNoInputOnChanged = (value) => {
    this.setState({
      certificateNo: value,
    });
  }

  onRef = (ref) => {
    this.countDownButton = ref;
  }

  // 发送短信验证码
  countDownBtnOnClicked = () => {
    const {
      openId,
      mobileNo,
    } = this.state;

    if (!!!mobileNo) {
      Toast.fail("请输入您的手机号", 2);
      return;
    }

    this.props.sendRegisterCaptcha(openId, mobileNo);
  }

  render() {
    const {
      name,
      mobileNo,
      certificateType,
      certificateNo,
      captcha,
    } = this.state;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>投资者注册</title>
        </Helmet>
        <div className="registerFormBox">
          <InputItem 
            value={name}
            className="registerFormInputItem"
            placeholder="请输入您的姓名" 
            onChange={this.nameInputOnChanged}/>
          <div className="registerCountDownBox">
            <InputItem 
              placeholder="请输入您的手机号" 
              className="registerMobileNoInputItem" 
              value={ mobileNo } 
              onChange={this.mobileNoInputOnChanged}/>
            <CountDownButton onRef={this.onRef}  buttonOnClick={this.countDownBtnOnClicked}/>
          </div>
          <InputItem 
            value={ captcha }
            onChange={this.captchaInputOnChanged}
            type='number'
            maxLength={6}
            className="registerAuthCodeInputItem" 
            placeholder="请输入验证码" />
          {/* 选择证件类型 */}
          <Select
            style={{ width: '100%', marginTop: 10 }}
            size="large"
            placeholder="请选择证件类型"
            value={certificateType}
            onChange={this.certTypeSelectOnChange} >
            {CertificateTypeOptions}
          </Select>
          {/* 证件号 */}
          <InputItem 
            value={certificateNo}
            placeholder="请输入您的证件号" 
            className="registerFormInputItem"
            onChange={this.certNoInputOnChanged} />
          <Button 
            style={{ backgroundColor: '#1970b6', color: 'white' }}
            className="registerBtn"
            onClick={this.registerBtnOnClicked}
          >立即注册</Button>
          <div className="hintBox">
            <div className="hintTitle">温馨提示:</div>
            <div className="hintLabel">注册信息一旦填写完成, 即不可修改;如果修改,请联系客服后台变更。</div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvestorRegister;