import {connect} from 'react-redux';
import InvestorRegisterBlank from '../components/InvestorRegisterBlank';

import {
  // Load WX Auth Info
  loadWXAuthInfo,
  initLoadWXAuthInfoActionStatus,

  // Load UserInfo By OpenId
  loadUserInfo,
  initLoadUserInfoActionStatus,
} from '../../home/homeActions';
import {
  // Load Risk Evaluations
  loadRiskEvaluations,
  initLoadRiskEvaluationsActionStatus,
  changeParams
} from '../investorRegisterActions';
const mapStateToProps = state => {
  return {
    // WX Auth Info
    loadWXAuthInfoActionStatus: state.home.loadWXAuthInfoActionStatus,
    wxAuthInfo: state.home.wxAuthInfo,

    // UserInfo By OpenId
    loadUserInfoActionStatus: state.home.loadUserInfoActionStatus,
    loadUserInfoResult: state.home.loadUserInfoResult,
    params: state.investorRegister.params,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load WX Auth Info
    loadWXAuthInfo: (code) => dispatch(loadWXAuthInfo(code)),
    initLoadWXAuthInfoActionStatus: () => dispatch(initLoadWXAuthInfoActionStatus()),

    // Load UserInfo By OpenId
    loadUserInfo: (openId) => dispatch(loadUserInfo(openId)),
    initLoadUserInfoActionStatus: () => dispatch(initLoadUserInfoActionStatus()),
    changeParams: params => dispatch(changeParams(params)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestorRegisterBlank);