import React, {Component} from 'react';
import { Button, Toast, Modal, Popover } from 'antd-mobile';
import Radio from 'antd/es/radio';
import Form from 'antd/es/form';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import './RiskEvaluation.css';

class PersonalRiskEvaluation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      riskOptions: [],
      totalScore: 0,
      scoreResult: 'C1',
      modelContent: '',
      modalVisible: false,
      popoverVisible: true,
      openId: props.history.location.state.openId,
      isExpired: props.history.location.state.isExpired,
      modalVisible001: props.history.location.state.isExpired === 'YES' && props.history.location.state.openId ==='olhXP1IekYMBUIL7Rj5Re7PMlxhU',
    };
  }
  
  componentDidMount() {
    
  }

  componentDidUpdate() {
    const {
      
      evaluationWXUserActionStatus,
    } = this.props;
    if (evaluationWXUserActionStatus === ACTION_STATUS.SUCCESS) {
      Toast.success("测评成功", 1);
      this.props.initEvaluationWXUserActionStatus();

      this.setState({
        name: '',
        mobileNo: '',
        captcha: '',
        certificateType: '0',
        certificateNo: '',
      });

      // 跳转到温馨提示页面
      this.props.history.replace({
        pathname: '/home/auth-query-blankT', 
        state: {
        }
      });
    }

    if (evaluationWXUserActionStatus === ACTION_STATUS.ERROR) {
      Toast.fail("测评失败", 1);
      this.props.initEvaluationWXUserActionStatus();
    }
  }

  // Risk Evaluation Radio Changed
  riskEvaluationRadioOnChanged = (data, e) => {
    const selectedValue = e.target.value;
    console.log('text ', selectedValue);

    let riskOptions = this.state.riskOptions;
    const filterRiskOption = riskOptions.filter(option => selectedValue.questionGuid === option.questionGuid);

    if (filterRiskOption.length !== 0) {
      let i = riskOptions.indexOf(filterRiskOption[0]);
      riskOptions.splice(i, 1);
    }
    
    riskOptions.push(selectedValue);

    this.setState({
      riskOptions,
    });
  };

  // 提交评测结果
  confirmBtnOnClick = () => {
    const riskOptions = this.state.riskOptions;
    const scoreArray = riskOptions.map(option => option.score);

    const isC1OptionArray = riskOptions.filter(option => option.isC1);

    const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

    let totalScore = 0;
    if (riskOptions.length !== 0) {
      totalScore = scoreArray.reduce(sumReducer);
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        let scoreResult = 'C1';
        let modelContent = `您当前得分为${totalScore}分, `;
        let isExpired = this.state.isExpired;
        if (isC1OptionArray.length === 0) {
          if (totalScore < 30) {
            scoreResult = 'C1';
            modelContent = modelContent + `投资者类型为${scoreResult},`;
            if(isExpired ==='YES'){
              modelContent = modelContent + ' 风险测评未通过';
            }else{
              modelContent = modelContent + ' 您当前不能注册';
            }
          } 
      
          if (totalScore >= 30 && totalScore < 50) {
            scoreResult = 'C2';
            modelContent = modelContent + `投资者类型为${scoreResult},`;
            if(isExpired ==='YES'){
              modelContent = modelContent + ' 风险测评未通过';
            }else{
              modelContent = modelContent + ' 您当前不能注册';
            }
          } 
      
          if (totalScore >= 50 && totalScore < 70) {
            scoreResult = 'C3';
            modelContent = modelContent + `投资者类型为${scoreResult},`;
            if(isExpired ==='YES'){
              modelContent = modelContent + ' 风险测评未通过';
            }else{
              modelContent = modelContent + ' 您当前不能注册';
            }
          } 
      
          if (totalScore >= 70 && totalScore < 90) {
            scoreResult = 'C4';
            modelContent = modelContent + `投资者类型为${scoreResult},`;
            if(isExpired ==='YES'){
              modelContent = modelContent + ' 点击 知道了 继续';
            }else{
              modelContent = modelContent + ' 点击 知道了 按钮进入注册页面';
            }
          } 
      
          if (totalScore >= 90 && totalScore <= 100) {
            scoreResult = 'C5';
            modelContent = modelContent + `投资者类型为${scoreResult}, `;
            if(isExpired ==='YES'){
              modelContent = modelContent + ' 点击 知道了 继续';
            }else{
              modelContent = modelContent + ' 点击 知道了 按钮进入注册页面';
            }
          } 
        } else {
          scoreResult = 'C1';
          modelContent = `因为您的答案中包含符合C1型-能力最低类别投资者的情形, 因此投资者类型直接判定为${scoreResult},`;
          if(isExpired ==='YES'){
            modelContent = modelContent + ' 无法继续';
          }else{
            modelContent = modelContent + ' 您当前不能注册';
          }
        }        
        
        this.setState({
          totalScore,
          scoreResult,
          modelContent,
          modalVisible: true,
        });

      } else {
        Toast.fail("您还有评测题目未选择", 2);
      }
    });
  }
  modalOnClose001 = () => {
    this.setState({
    
      modalVisible001: false,
    });
  }
  modalOnClose = () => {
    const scoreResult = this.state.scoreResult;

    const score = this.state.totalScore;
    const rating = this.state.scoreResult;
    if (scoreResult === 'C4' || scoreResult === 'C5') {
      this.setState({
        riskOptions: [],
        totalScore: 0,
        scoreResult: 'C1',
        modelContent: '',
        modalVisible: false,
      });
      
      this.props.changeParams({
        score,
        rating,  
      });
      const openId = this.state.openId;
      const isExpired = this.state.isExpired;
      if(isExpired === 'YES'){
        this.props.evaluationWXUser({
          openId,
          score,
          rating,
          
        });
      }else{
        this.props.history.replace({
          pathname: '/home/investor-register-blank',
          state: {
            score,
            rating,  
          }
        });
      }
     
      // this.props.history.replace({
      //   pathname: '/home/investor-register-blank',
      // });
    } else {
      this.setState({
        modalVisible: false,
      });
    } 
  }
  popoverOnclick = () => {
    this.setState({
      ...this.state,
      popoverVisible: false,
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      // Load Risk Evaluations
      loadRiskEvaluationsActionStatus,
      personalRiskEvaluations,
      popoverVisible
    } = this.props;

    const {
      modalVisible,
      modalVisible001,
      modelContent,
    } = this.state;

    return (
      
      <div>
        {true   && (
          <div>
          <Popover mask
            visible={popoverVisible}
            overlay={[
              (
                <span>
                    <h2 align="center">合格投资者确认书</h2>
                    <div><br/>请仔细并完整阅读以下重要提示，根据您的具体情况进行选择：</div>
                    <div><br/>请您确认您或您所代表的机构是符合《中华人民共和国证券投资基金法》《私募投资基金监督管理条例》《私募投资基金监督管理暂行办法》《私募投资基金募集行为管理办法》《证券期货投资者适当性管理办法》及其他相关法律法规所认定的合格投资者</div>
                    <div><br/>根据我国《私募投资基金募集行为管理办法》的规定，私募基金合格投资者的标准如下：</div>
                    <div><br/>1.	具备相应风险识别能力和风险承担能力，投资于单只私募基金的金额不低于100万元且符合下列相关标准的单位和个人：</div>
                    <div><br/>(1)	净资产不低于1000万元的机构；</div>
                    <div>(2)	金融资产不低于300万元或者最近三年个人年均收入不低于50万元的个人。
                    前款所称金融资产包括银行存款、股票、债券、基金份额、资产管理计划、银行理财产品、信托计划、保险产品、期货权益等。
                    </div>
                    <div><br/>2.	下列投资者视为合格投资者：</div>
                    <div><br/>(1)	社会保障基金、企业年金等养老基金，慈善基金等社会公益基金；</div>
                    <div>(2)	依法设立并在中国基金业协会备案的私募基金产品；</div>
                    <div>(4)	投资于所管理私募基金的私募基金管理人及其从业人员；</div>
                    <div>(5)	法律法规、中国证监会和中国基金业协会规定的其他投资者。</div>
                    <div><br/>上海保银私募基金管理有限公司（以下简称“上海保银私募”）微信服务号所包含的信息和材料仅供一般性参考，不构成任何关于金融、财务、法律、税务或其他方面的意见或建议。市场有风险，投资需谨慎，过往业绩不预示未来表现。您在做出投资决策前应认真阅读相关产品合同，并自行承担投资风险。</div>
                    <div><br/>上海保银私募微信服务号由上海保银私募拥有和运营。上海保银私募微信服务号所载信息或材料均属于上海保银私募所有或关联公司所有，或者已经取得有权第三方必要授权。上海保银私募有权随时修改或删除上海保银私募微信服务号所载信息或材料。未经上海保银私募事先书面同意，任何人不得复制、摘编、引用、改写上海保银私募微信服务号所载信息或材料，亦不得以转载、转发、分发、存储于检索系统等形式将上海保银私募微信服务号所载信息或材料的全部或者部分用作任何商业或公共用途。</div>
                    <div><br/>A.	我确认我或我所代表的机构符合“合格投资者”标准，而且同意接受上述所有内容</div>
                    <div>B.	我或我所代表的机构不符合“合格投资者”标准，或者不同意接受上述内容</div>
                    <div align="center"><br/>
                      <Button size="small" 
                          type="primary"
                          inline
                          size="small"
                          onClick={this.popoverOnclick}
                      >A:同&nbsp;&nbsp;意</Button>
                      <Button size="small" 
                          type="primary"
                          inline
                          size="small"
                          
                      >B:不同意</Button>
                    </div>
                </span>
              ),
            ]}
            align={{
              overflow: { adjustY: -1, adjustX: -1 },
              offset: [0, 0],
            }}
          >
            <div></div>
        </Popover>
                     </div>
        )}
        {true   && (
        <div>
        <p className='riskTip'>
          风险提示：基金投资需承担各类风险，本金可能遭受失。同时，还要考虑市场风险、信用风险、流动风险、操作风险等各类投资风险。
          您在基金认购过程中应当注意核对自己的风险识别和风险承受能力，选择与自己风险识别能力和风险承受能力相匹配的私募基金。 
        </p>
        {
          personalRiskEvaluations.map((value, index) => {
            return (
              <Form.Item key={value.guid}>
                {getFieldDecorator(`${value.guid}`, {
                  rules: [
                    { required: true, message: '您必须选择一个选项', type: 'string' },
                  ],
                })(
                <div className='riskEvaluationQuestionBox' key={value.guid}>
                  {value.content}
                  <Radio.Group onChange={this.riskEvaluationRadioOnChanged.bind(this, value)}>
                    {
                      value.riskEvaluationOptions.map((valueOption, indexOption) => {
                        return (
                          <Radio value={valueOption} key={valueOption.guid}>{ `${valueOption.content} ` }</Radio>
                        );
                      })
                    }
                  </Radio.Group>
                </div>
              )}
              </Form.Item>
            );
          })
        }
        <div className='personalRiskBtnBox'>
          <Button 
            type="primary" 
            inline 
            size="small" 
            style={{ width: 100 }}
            onClick={this.confirmBtnOnClick}>确定</Button>
        </div>
        <Modal
          visible={modalVisible}
          transparent
          maskClosable={false}
          title="风险评测问卷结果"
          onClose={this.modalOnClose}
          footer={[{ text: '知道了...', onPress: this.modalOnClose }]}
        >
          {modelContent}
        </Modal>
        {/* <Modal
          visible={modalVisible001}
          transparent
          maskClosable={false}
          title="风险评测问卷"
          onClose={this.modalOnClose}
          footer={[{ text: '知道了...', onPress: this.modalOnClose }]}
        >
          {'您的风险测评问卷已过期，请重新填写，感谢您的配合！'}
        </Modal> */}
        </div>
        )}
      </div>
    );
  }
}

const PersonalRiskEvaluationForm = Form.create({ name: 'personal-risk-evaluation' })(PersonalRiskEvaluation);

export default PersonalRiskEvaluationForm;