import React, {Component} from 'react';
import { SegmentedControl } from 'antd-mobile';
import ReactList from 'react-list';
import { Helmet } from "react-helmet";
import FundValueTable from './FundValueTable';
import TradeRecordTable from './TradeRecordTable';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

import './PersonalFundDetail.css';

class PersonalFundDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      personalFund: props.history.location.state.personalFund,
      personalFundBasicInfo: props.personalFundBasicInfo,
    };
  }

  componentDidMount() {
    const entityGuid = this.state.personalFund.entityGuid;
    const investorGuid = this.state.personalFund.investorGuid;
    const entityName = this.state.personalFund.entityName;
    const entityAreaType = this.state.personalFund.entityAreaType;
    const priceDate = this.state.personalFund.priceDateLabel;

    // 对于海外，展示母基金信息
    // 对于DCL净值法基金，展示上一级基金，即子基金信息
    // 对于其他国内，展示自己，即子基金信息
    let fundGuid = null;
    if(entityName.startsWith('DCL') || entityAreaType==='2') {
      fundGuid = this.state.personalFund.parentEntityGuid;
    } else {
      fundGuid = this.state.personalFund.entityGuid;
    }

    this.props.loadPersonalFundBasicInfo(fundGuid);
    this.props.loadPersonalFundValues({entityGuid, priceDate});
    this.props.loadPersonalTradeRecord(investorGuid, entityGuid);
  }

  componentDidUpdate() {
    const {
      loadPersonalFundBasicInfoActionStatus,
      personalFundBasicInfo,
    } = this.props;

    if (loadPersonalFundBasicInfoActionStatus === ACTION_STATUS.SUCCESS) {
      this.setState({
        personalFundBasicInfo,
      });

      this.props.initLoadPersonalFundBasicInfoActionStatus();
    }
  }

  renderItem(index, key) {
    return <div key={key} className="personalBasicInfoRowItemBox">
      <div className="personalBasicInfoField">{this.state.personalFundBasicInfo[index].field}</div>
      <div className="personalBasicInfoLabel">{this.state.personalFundBasicInfo[index].label}</div>
    </div>;
  }

  segmentedControlOnValueChanged = (value) => {
    if (value === '基金概述') {
      this.setState({
        tabIndex: 2
      });
    }

    if (value === '交易历史') {
      this.setState({
        tabIndex: 1
      });
    }

    if (value === '产品净值') {
      this.setState({
        tabIndex: 0
      });
    }
  }

  navLeftButtonOnClicked = () => {
    this.props.history.goBack();
  }

  handleScroll = () => {

  }

  render() {
    const {
      tabIndex,
      personalFund,
      personalFundBasicInfo,
    } = this.state;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>基金详情</title>
        </Helmet>
        <div className="personalFundDetailBox">
          <div className="personalFundDetailRowTitleBox">
            <div className="personalFundDetailTitleBox" />
            <span className="personalFundDetailTitle">{ personalFund.fundTitle }</span>
          </div>
          <div className="personalFundDetailValueBox">
            <div className="personalFundDetailTotalReturnBox">
              <span className="personalFundDetailTotalReturnValue">{ personalFund.totalAmount }</span>
              <span className="personalFundDetailTotalReturnLabel">{ personalFund.currencyLabel }</span>
              {/* <span className="personalFundDetailTotalReturnTipLabel">( 费后市值 )</span> */}
            </div>
            <div className="personalFundDetailValueRow2Box">
              <div className="personalFundDetailPriceBox">
                <span className="personalFundDetailPriceValue">{ personalFund.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? '-' : personalFund.price }</span>
                {/* <span className="personalFundDetailPriceLabel">{ personalFund.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? '单位净值(更新日期：不适用)' : `单位净值(更新日期：${personalFund.priceDateLabel})` }</span> */}
                <span className="personalFundDetailPriceLabel">{ personalFund.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? `(市值更新日期：${personalFund.priceDateLabel})` : `单位净值(更新日期：${personalFund.priceDateLabel})` }</span>
              </div>
              <div className="personalFundDetailShareBox">
                <span className="personalFundDetailShareValue">{ personalFund.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? '-' : personalFund.totalShare }</span>
                {/* <span className="personalFundDetailShareLabel">份额(份)</span> */}
                <span className="personalFundDetailShareLabel">{ personalFund.parentEntityGuid == 'F3819BD6D54D4069ACBE5EE008592953' ? '基金为合伙人制,无单位净值' : '份额(份)' }</span>
              </div>
            </div>
          </div>
        </div>
        <div className="personalFundDetailSegmentedControlBox">
          <SegmentedControl 
            selectedIndex={tabIndex} 
            onValueChange={this.segmentedControlOnValueChanged}
            tintColor={'#1970b6'}
            values={['产品净值', '交易历史', '基金概述']} />
        </div>
        {/* 基金概述 */}
        {
          tabIndex === 0 && 
          <div className="personalFundValueGridBox">
            <FundValueTable {...this.props} />
          </div>
        }
        {/* 交易历史 */}
        {
          tabIndex === 1 && 
          <div className="tradeRecordBox">
            <TradeRecordTable {...this.props} />
          </div>
        }
        {/* 产品净值 */}
        {
          tabIndex === 2 && 
          <div className="personalFundDetailBasicInfo">
            <span className="personalBasicInfoTitle">基本信息</span>
            <div className="personalBasicInfoListBox">
              <ReactList
                style={{height: 300, overflow: 'auto'}} onScroll={this.handleScroll}
                itemRenderer={this.renderItem.bind(this)}
                length={personalFundBasicInfo.length}
                type='uniform'
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default PersonalFundDetail;