import React, {Component} from 'react';
import { Button, InputItem, Toast } from 'antd-mobile';
import { Helmet } from "react-helmet";
import CountDownButton from '../../../components/CountDownButton';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import WXAuthUtil from '../../../../utils/WXAuthUtil';
import './AuthQuery.css';
import qs from 'qs';
class AuthQuery extends Component {

  onUnload(event) { 
    alert('页面刷新')
  }
  componentWillUnmount() {

    window.removeEventListener("beforeunload", this.onUnload)
    
    }
  componentDidMount() {

    window.addEventListener("beforeunload", this.onUnload)

    if (performance.navigation.type == 1) {
      console.log(this.props.history.location.state);


      const searchString = window.location.search;
    
      if (searchString === '') {
        window.location.href = WXAuthUtil.AUTH_QUERY_AUTH_URL;
      }

  


      // searchString 不为空
      if (!!searchString) {
        const searchObj = qs.parse(searchString, { ignoreQueryPrefix: true });
        const code = searchObj.code;
        this.props.loadWXAuthInfo(code);
      }
  
    }  
    

    // this.props.initAuthCaptchaActionStatus();

    // const userGuid = this.state.userGuid;
    // const openId = this.state.openId;
    // const userName = this.state.userName;
    
    // this.props.history.replace({
    //   pathname: '/home/personal-fund', 
    //   state: {
    //     openId,
    //     userGuid,
    //     userName,
    //   }
    // });

    
  }


  constructor(props) {
    super(props);

    this.state = {
      openId: props.history.location.state.openId,
      userGuid: props.history.location.state.userGuid,
      mobileNo: props.history.location.state.mobileNo,
      userName: props.history.location.state.userName,
      captcha: '',
    };
  }

  componentDidUpdate() {
    const {
      // Send Captcha
      sendCaptchaActionStatus,

      // Auth Captcha
      authCaptchaActionStatus,
    } = this.props;

    // Send Captcha
    if (sendCaptchaActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initSendCaptchaActionStatus();
    }

    if (sendCaptchaActionStatus === ACTION_STATUS.ERROR) {
      this.props.initSendCaptchaActionStatus();
    }

    // Auth Captcha
    if (authCaptchaActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initAuthCaptchaActionStatus();

      const userGuid = this.state.userGuid;
      const openId = this.state.openId;
      const userName = this.state.userName;
      
      this.props.history.replace({
        pathname: '/home/personal-fund', 
        state: {
          openId,
          userGuid,
          userName,
        }
      });
    }

    if (authCaptchaActionStatus === ACTION_STATUS.ERROR) {
      this.props.initAuthCaptchaActionStatus();
      Toast.fail("校验失败了", 2);
    }
  }

  onRef = (ref) => {
    this.countDownButton = ref;
  }

  captchaInputOnChanged = (value) => {
    this.setState({
      ... this.state,
      captcha: value,
    });
  }

  countDownBtnOnClicked = () => {
    this.countDownButton.startCountDown();  // 倒计时
    const openId = this.state.openId;
    this.props.sendCaptcha(openId);
  }

  // 提交校验
  confirmBtnOnClick = () => {
    ////////////////////
    const isErroruserResult = this.props.isErroruserResult;
    if(isErroruserResult) {
        Toast.fail("年末与托管行数据核对中，暂停数据查询，会尽快开放，给您带来的不便，敬请谅解！", 2);
        return;
    }


    
    const authToken = this.props.authToken;

    const {
      openId,
      captcha,
      mobileNo
    } = this.state;

    this.props.authCaptcha(openId, mobileNo, authToken, captcha);
  }

  render() {
    const {
      mobileNo,
      captcha,
    } = this.state;

    const confirmBtnDisabled = !!!captcha;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>查询验证</title>
        </Helmet>
        <div className="authQueryBox">
          <div className="countDownBox">
            <InputItem 
              className="phoneNoInputItem" disabled={true} value={ mobileNo } />
            <CountDownButton onRef={this.onRef}  buttonOnClick={this.countDownBtnOnClicked}/>
          </div>
          <InputItem 
            value={ captcha }
            onChange={this.captchaInputOnChanged}
            type='number'
            maxLength={6}
            className="authCodeInputItem" 
            placeholder="请输入验证码" />
          <Button 
            style={{ backgroundColor: '#1970b6', color: 'white' }}
            className="confirmBtn"
            disabled={confirmBtnDisabled}
            onClick={this.confirmBtnOnClick}>确定</Button>
            <div>重要提示: 本微信服务号于2024年8月1日起停止美元基金查询，请通过海外托管行发送的邮件进行查询。</div>
          <div>温馨提示: 国内基金数据已经通过托管行复核，基金净值为管理费后，表现费前。</div>
        </div>
      </div>
    );
  }
}

export default AuthQuery;