import {connect} from 'react-redux';
import InvestorRegister from '../components/InvestorRegister';

import {
  // WX User Register
  registerWXUser,
  initRegisterWXUserActionStatus,

  // Send WX User Register Captcha
  sendRegisterCaptcha,
  initSendRegisterCaptchaActionStatus,
  evaluationWXUser,
  initEvaluationWXUserActionStatus,
} from '../investorRegisterActions';

const mapStateToProps = state => {
  return {
    // WX User Register
    registerWXUserActionStatus: state.investorRegister.registerWXUserActionStatus,

    // Send WX User Register Captcha
    sendRegisterCaptchaActionStatus: state.investorRegister.sendRegisterCaptchaActionStatus,
    sendRegisterCaptchaAuthToken: state.investorRegister.sendRegisterCaptchaAuthToken,
    params: state.investorRegister.params,

    evaluationWXUserActionStatus: state.investorRegister.evaluationWXUserActionStatus,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // WX User Register
    registerWXUser: (wxUserInfo) => dispatch(registerWXUser(wxUserInfo)),
    initRegisterWXUserActionStatus: () => dispatch(initRegisterWXUserActionStatus()),

    // Send WX User Register Captcha
    sendRegisterCaptcha: (openId, phoneNo) => dispatch(sendRegisterCaptcha(openId, phoneNo)),
    initSendRegisterCaptchaActionStatus: () => dispatch(initSendRegisterCaptchaActionStatus()),

    evaluationWXUser: (wxUserInfo) => dispatch(evaluationWXUser(wxUserInfo)),
    initEvaluationWXUserActionStatus: () => dispatch(initEvaluationWXUserActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestorRegister);