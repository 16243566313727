// WX User Register
export const START_REGISTER_WX_USER = 'START_REGISTER_WX_USER';
export const REGISTER_WX_USER_SUCCESS = 'REGISTER_WX_USER_SUCCESS';
export const REGISTER_WX_USER_FAILURE = 'REGISTER_WX_USER_FAILURE';
export const INIT_REGISTER_WX_USER_ACTION_STATUS = 'INIT_REGISTER_WX_USER_ACTION_STATUS';

// Send WX User Register Captcha
export const START_SEND_REGISTER_CAPTCHA = 'START_SEND_REGISTER_CAPTCHA';
export const SEND_REGISTER_CAPTCHA_SUCCESS = 'SEND_REGISTER_CAPTCHA_SUCCESS';
export const SEND_REGISTER_CAPTCHA_FAILURE = 'SEND_REGISTER_CAPTCHA_FAILURE';
export const INIT_SEND_REGISTER_CAPTCHA_ACTION_STATUS = 'INIT_SEND_REGISTER_CAPTCHA_ACTION_STATUS';

// Load Risk Evaluations
export const START_LOAD_RISK_EVALUATIONS = 'START_LOAD_RISK_EVALUATIONS';
export const LOAD_RISK_EVALUATIONS_SUCCESS = 'LOAD_RISK_EVALUATIONS_SUCCESS';
export const LOAD_RISK_EVALUATIONS_FAILURE = 'LOAD_RISK_EVALUATIONS_FAILURE';
export const INIT_LOAD_RISK_EVALUATIONS_ACTION_STATUS = 'INIT_LOAD_RISK_EVALUATIONS_ACTION_STATUS';


export const CHANGE_PARAMS = 'CHANGE_PARAMS';


// WX User
export const START_EVALUATION_WX_USER = 'START_EVALUATION_WX_USER';
export const EVALUATION_WX_USER_SUCCESS = 'EVALUATION_WX_USER_SUCCESS';
export const EVALUATION_WX_USER_FAILURE = 'EVALUATION_WX_USER_FAILURE';
export const INIT_EVALUATION_WX_USER_ACTION_STATUS = 'INIT_EVALUATION_WX_USER_ACTION_STATUS';