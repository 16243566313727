import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';

import {
  // Send Captcha
  START_SEND_CAPTCHAT,
  SEND_CAPTCHA_SUCCESST,
  SEND_CAPTCHA_FAILURET,
  INIT_SEND_CAPTCHA_ACTION_STATUST,

  // Auth Captcha
  START_AUTH_CAPTCHAT,
  AUTH_CAPTCHA_SUCCESST,
  AUTH_CAPTCHA_FAILURET,
  INIT_AUTH_CAPTCHA_ACTION_STATUST,

  // auth password
  START_WX_CHECK_PASSWORDT,
  WX_CHECK_PASSWORD_SUCCESST,
  WX_CHECK_PASSWORD_FAILURET,
  INIT_WX_CHECK_PASSWORD_ACTION_STATUST,



  
    ///////////
    START_IS_ERROR_USERT,
    IS_ERROR_USER_SUCCESST,
    IS_ERROR_USER_FAILURET,
    INIT_IS_ERROR_USER_ACTION_STATUST,




} from './authQueryConstants';


const initialState = {
  // Send Captcha
  sendCaptchaActionStatusT: ACTION_STATUS.READY,
  authTokenT: '',

  // Auth Captcha
  authCaptchaActionStatusT: ACTION_STATUS.READY,
}

// Send Captcha
function startSendCaptcha(state) {
  return {
    ...state,
    sendCaptchaActionStatusT: ACTION_STATUS.LOGINING,
  };
}

function sendCaptchaSuccess(state, resp) {

  return {
    ...state,
    sendCaptchaActionStatusT: ACTION_STATUS.SUCCESS,
    authTokenT: resp.resultData.authToken,
  };
}

function sendCaptchaFailure(state, err) {
  return {
    ...state,
    sendCaptchaActionStatusT: ACTION_STATUS.ERROR,
  };
}

function initSendCaptchaActionStatus(state) {
  return {
    ...state,
    sendCaptchaActionStatusT: ACTION_STATUS.READY,
  };
}

// Auth Captcha
function startAuthCaptcha(state) {
  return {
    ...state,
    authCaptchaActionStatusT: ACTION_STATUS.LOGINING,
  };
}

function authCaptchaSuccess(state, resp) {
  return {
    ...state,
    authCaptchaActionStatusT: ACTION_STATUS.SUCCESS,
  };
}

function authCaptchaFailure(state, err) {
  return {
    ...state,
    authCaptchaActionStatusT: ACTION_STATUS.ERROR,
  };
}

function initAuthCaptchaActionStatus(state) {
  return {
    ...state,
    authCaptchaActionStatusT: ACTION_STATUS.READY,
  };
}

// auth password
function startWxCheckPassword(state) {
  return {
    ...state,
    wxCheckPasswordActionStatusT: ACTION_STATUS.LOGINING,
  };
}
function wxCheckPasswordSuccess(state, resp) {
  return {
    ...state,
    wxCheckPasswordActionStatusT: ACTION_STATUS.SUCCESS,
  };
}
function wxCheckPasswordFailure(state, err) {
  return {
    ...state,
    wxCheckPasswordActionStatusT: ACTION_STATUS.ERROR,
  };
}
function initWxCheckPasswordActionStatus(state) {
  return {
    ...state,
    wxCheckPasswordActionStatusT: ACTION_STATUS.READY,
  };
}


////////////////
function startIsErrorUser(state) {
  return {
    ...state,
    isErrorUserStatusT: ACTION_STATUS.LOGINING,
  };
}
function isErrorUserSuccess(state, resp) {
  return {
    ...state,
    isErrorUserStatusT: ACTION_STATUS.SUCCESS,
    isErroruserResultT: false,
  };
}
function isErrorUserFailure(state, err) {
  return {
    ...state,
    isErrorUserStatusT: ACTION_STATUS.ERROR,
    isErroruserResultT: true,
  };
}
function initIsErrorUserActionStatus(state) {
  return {
    ...state,
    isErrorUserStatusT: ACTION_STATUS.READY,
  };
}



export default createReducer(initialState, {
  // Send Captcha
  [START_SEND_CAPTCHAT]: startSendCaptcha,
  [SEND_CAPTCHA_SUCCESST]: sendCaptchaSuccess,
  [SEND_CAPTCHA_FAILURET]: sendCaptchaFailure,
  [INIT_SEND_CAPTCHA_ACTION_STATUST]: initSendCaptchaActionStatus,

  // Auth Captcha
  [START_AUTH_CAPTCHAT]: startAuthCaptcha,
  [AUTH_CAPTCHA_SUCCESST]: authCaptchaSuccess,
  [AUTH_CAPTCHA_FAILURET]: authCaptchaFailure,
  [INIT_AUTH_CAPTCHA_ACTION_STATUST]: initAuthCaptchaActionStatus,

  // auth password
  [START_WX_CHECK_PASSWORDT]: startWxCheckPassword,
  [WX_CHECK_PASSWORD_SUCCESST]: wxCheckPasswordSuccess,
  [WX_CHECK_PASSWORD_FAILURET]: wxCheckPasswordFailure,
  [INIT_WX_CHECK_PASSWORD_ACTION_STATUST]: initWxCheckPasswordActionStatus,


  // 
  [START_IS_ERROR_USERT]: startIsErrorUser,
  [IS_ERROR_USER_SUCCESST]: isErrorUserSuccess,
  [IS_ERROR_USER_FAILURET]: isErrorUserFailure,
  [INIT_IS_ERROR_USER_ACTION_STATUST]: initIsErrorUserActionStatus,

});