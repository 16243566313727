import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';

import {
  // WX User Register
  START_REGISTER_WX_USER,
  REGISTER_WX_USER_SUCCESS,
  REGISTER_WX_USER_FAILURE,
  INIT_REGISTER_WX_USER_ACTION_STATUS,
 
  // Send WX User Register Captcha
  START_SEND_REGISTER_CAPTCHA,
  SEND_REGISTER_CAPTCHA_SUCCESS,
  SEND_REGISTER_CAPTCHA_FAILURE,
  INIT_SEND_REGISTER_CAPTCHA_ACTION_STATUS,

  // Load Risk Evaluations
  START_LOAD_RISK_EVALUATIONS,
  LOAD_RISK_EVALUATIONS_SUCCESS,
  LOAD_RISK_EVALUATIONS_FAILURE,
  INIT_LOAD_RISK_EVALUATIONS_ACTION_STATUS,
  CHANGE_PARAMS,



  START_EVALUATION_WX_USER,
  EVALUATION_WX_USER_SUCCESS,
  EVALUATION_WX_USER_FAILURE,
  INIT_EVALUATION_WX_USER_ACTION_STATUS,

 } from './investorRegisterConstants';


const initialState = {
  // WX User Register
  registerWXUserActionStatus: ACTION_STATUS.READY,
  evaluationWXUserActionStatus: ACTION_STATUS.READY,
  // Send WX User Register Captcha
  sendRegisterCaptchaActionStatus: ACTION_STATUS.READY,
  sendRegisterCaptchaAuthToken: '',

  // Load Risk Evaluations
  loadRiskEvaluationsActionStatus: ACTION_STATUS.READY,
  riskEvaluations: [],
  personalRiskEvaluations: [],
  orgRiskEvaluations: [],
  params: {
    score: null,
    rating: null 
  }
}

// WX User Register
function startRegisterWXUser(state) {
  return {
    ...state,
    registerWXUserActionStatus: ACTION_STATUS.LOGINING,
  };
}

function registerWXUserSuccess(state, resp) {
  return {
    ...state,
    registerWXUserActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function registerWXUserFailure(state, err) {
  return {
    ...state,
    registerWXUserActionStatus: ACTION_STATUS.ERROR,
  };
}

function initRegisterWXUserActionStatus(state) {
  return {
    ...state,
    registerWXUserActionStatus: ACTION_STATUS.READY,
  };
}

// Send WX User Register Captcha
function startSendRegisterCaptcha(state) {
  return {
    ...state,
    sendRegisterCaptchaActionStatus: ACTION_STATUS.LOGINING,
  };
}

function sendRegisterCaptchaSuccess(state, resp) {
  return {
    ...state,
    sendRegisterCaptchaActionStatus: ACTION_STATUS.SUCCESS,
    sendRegisterCaptchaAuthToken: resp.resultData.authToken,
  };
}

function sendRegisterCaptchaFailure(state, err) {
  return {
    ...state,
    sendRegisterCaptchaActionStatus: ACTION_STATUS.ERROR,
  };
}

function initSendRegisterCaptchaActionStatus(state) {
  return {
    ...state,
    sendRegisterCaptchaActionStatus: ACTION_STATUS.READY,
  };
}


// Load Risk Evaluations
function startLoadRiskEvaluations(state) {
  return {
    ...state,
    loadRiskEvaluationsActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRiskEvaluationsSuccess(state, resp) {
  const riskEvaluations = resp.resultData;
  const personalRiskEvaluations = riskEvaluations.filter(riskEvaluation => riskEvaluation.versionType === '1');
  const orgRiskEvaluations = riskEvaluations.filter(riskEvaluation => riskEvaluation.versionType === '2');

  return {
    ...state,
    loadRiskEvaluationsActionStatus: ACTION_STATUS.SUCCESS,
    riskEvaluations,
    personalRiskEvaluations,
    orgRiskEvaluations,
  };
}

function loadRiskEvaluationsFailure(state, err) {
  return {
    ...state,
    loadRiskEvaluationsActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadRiskEvaluationsActionStatus(state) {
  return {
    ...state,
    loadRiskEvaluationsActionStatus: ACTION_STATUS.READY,
  };
}

function changeParams(state, params) {
  return {
    ...state,
    params,
  };
}
// WX User evaluation
function startEvaluationWXUser(state) {
  return {
    ...state,
    evaluationWXUserActionStatus: ACTION_STATUS.LOGINING,
  };
}

function evaluationWXUserSuccess(state, resp) {
  return {
    ...state,
    evaluationWXUserActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function evaluationWXUserFailure(state, err) {
  return {
    ...state,
    evaluationWXUserActionStatus: ACTION_STATUS.ERROR,
  };
}

function initEvaluationWXUserActionStatus(state) {
  return {
    ...state,
    evaluationWXUserActionStatus: ACTION_STATUS.READY,
  };
}
export default createReducer(initialState, {
  // WX User Register
  [START_REGISTER_WX_USER]: startRegisterWXUser,
  [REGISTER_WX_USER_SUCCESS]: registerWXUserSuccess,
  [REGISTER_WX_USER_FAILURE]: registerWXUserFailure,
  [INIT_REGISTER_WX_USER_ACTION_STATUS]: initRegisterWXUserActionStatus,

  // Send WX User Register Captcha
  [START_SEND_REGISTER_CAPTCHA]: startSendRegisterCaptcha,
  [SEND_REGISTER_CAPTCHA_SUCCESS]: sendRegisterCaptchaSuccess,
  [SEND_REGISTER_CAPTCHA_FAILURE]: sendRegisterCaptchaFailure,
  [INIT_SEND_REGISTER_CAPTCHA_ACTION_STATUS]: initSendRegisterCaptchaActionStatus,

  // Load Risk Evaluations
  [START_LOAD_RISK_EVALUATIONS]: startLoadRiskEvaluations,
  [LOAD_RISK_EVALUATIONS_SUCCESS]: loadRiskEvaluationsSuccess,
  [LOAD_RISK_EVALUATIONS_FAILURE]: loadRiskEvaluationsFailure,
  [INIT_LOAD_RISK_EVALUATIONS_ACTION_STATUS]: initLoadRiskEvaluationsActionStatus,

  [CHANGE_PARAMS]: changeParams,

  [START_EVALUATION_WX_USER]: startEvaluationWXUser,
  [EVALUATION_WX_USER_SUCCESS]: evaluationWXUserSuccess,
  [EVALUATION_WX_USER_FAILURE]: evaluationWXUserFailure,
  [INIT_EVALUATION_WX_USER_ACTION_STATUS]: initEvaluationWXUserActionStatus,
});