import React, {Component} from 'react';
import { Helmet } from "react-helmet";
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import WXAuthUtil from '../../../../utils/WXAuthUtil';
import qs from 'qs';

class InvestorRegisterBlank extends Component {

  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     score: props.history.location.state.score,
  //     rating: props.history.location.state.rating,

  //   };
  // }
  constructor(props) {
    super(props);

    this.state = {
      score: this.props.params.score,
      rating: this.props.params.rating,
    };
  }

  componentWillMount() {
    const searchString = window.location.search;
    
    if (searchString === '') {

      const { score, rating } = this.state;
      sessionStorage.setItem('score', score);
      sessionStorage.setItem('rating', rating);
      // const redirectUrl = `${WXAuthUtil.INVESTOR_REGISTER_AUTH_URL}?score=${score}&rating=${rating}`;
      // window.location.href = redirectUrl;

      window.location.href = WXAuthUtil.INVESTOR_REGISTER_AUTH_URL;
    }
  }

  componentDidMount() {
    const searchString = window.location.search;

    // searchString 不为空
    if (!!searchString) {

      const searchObj = qs.parse(searchString, { ignoreQueryPrefix: true });
      const code = searchObj.code;
      const score = searchObj.score;
      const rating = searchObj.rating;
  
      // 将查询参数解析后存入 state 中
      this.setState({ score, rating });
      this.props.loadWXAuthInfo(code);



      // const searchObj = qs.parse(searchString, { ignoreQueryPrefix: true });
      // const code = searchObj.code;
      // this.props.loadWXAuthInfo(code);
    }
  }

  componentDidUpdate() {
    const {
      loadWXAuthInfoActionStatus,
      wxAuthInfo,

      // UserInfo By OpenId
      loadUserInfoActionStatus,
      loadUserInfoResult,
    } = this.props;

    // 查询基金授权信息
    if (loadWXAuthInfoActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadWXAuthInfoActionStatus();

      if (wxAuthInfo.hasOwnProperty('errcode')) {
        window.location.href = WXAuthUtil.INVESTOR_REGISTER_AUTH_URL;
        return;
      }

      const openId = wxAuthInfo.openid;
      this.props.loadUserInfo(openId);
    }
    
    if (loadWXAuthInfoActionStatus === ACTION_STATUS.ERROR) {
      this.props.initLoadWXAuthInfoActionStatus();
    }

    // 查询微信openId关联的用户信息
    if (loadUserInfoActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadUserInfoActionStatus();

      const name = loadUserInfoResult.resultData.name;
      this.props.history.replace({
        pathname: '/home/investor-registered-hint', 
        state: {
          name,
        }
      });
    }
    
    if (loadUserInfoActionStatus === ACTION_STATUS.ERROR) {
      this.props.initLoadUserInfoActionStatus();
      // const {
      //   score,
      //   rating,

      // } = this.state;
      const score = sessionStorage.getItem('score');
      const rating = sessionStorage.getItem('rating');
      const openId = wxAuthInfo.openid;
      this.props.history.replace({
        pathname: '/home/investor-register', 
        state: {
          openId,
          score,
          rating,
        }
      });
    }

  }

  render() {
    // const {
    //   score,
    //   rating,

    // } = this.state;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>保银投资客户服务</title>
        </Helmet>
      </div>
    );
  }
}

export default InvestorRegisterBlank;